import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import {
  Button,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
  Table,
  Dropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Bar, Doughnut } from "react-chartjs-2";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { selectUser } from "../redux/slice/userSlice";
// import { selectClient } from '../redux/slice/configSlice'
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import Loader from "../components/loader/Loader";
import feather from "feather-icons/dist/feather";
// import { DataGrid, GridRowsProp, GridColDef, GridToolbar  } from '@mui/x-data-grid';
import {
  DataGrid,
  GroupPanel,
  Column,
  HeaderFilter,
  Search,
  ColumnChooser,
  ColumnFixing,
  Scrolling,
  Export,
} from "devextreme-react/data-grid";

import * as GridMainService from "../services/grid_main_dashboard";

import Select from "react-select";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";

import { exportDataGrid } from "devextreme/excel_exporter";
import saveAs from "file-saver";
import ExcelJS from "exceljs";

export default function Auditorias() {
  const userData = useSelector(selectUser);
  const clientSelect = "";
  const [isLoading, setisLoading] = useState(false);
  const [dataGrid, SetDataGrid] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedEstablecimiento, setSelectedEstablecimiento] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const db = getFirestore();

  const dataGridRef = React.useRef(null);

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 7))
  );
  const [endDate, setEndDate] = useState(new Date());

  const [skuYesPercentage, setSkuYesPercentage] = useState(0);
  const [skuNoPercentage, setSkuNoPercentage] = useState(0);
  const [popYesPercentage, setPopYesPercentage] = useState(0);
  const [popNoPercentage, setPopNoPercentage] = useState(0);
  const [exhibicionYesPercentage, setExhibicionYesPercentage] = useState(0);
  const [exhibicionNoPercentage, setExhibicionNoPercentage] = useState(0);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  // this.onExporting = this.onExporting.bind(this);

  switchSkin(skin);

  useEffect(() => {
    GetDataGridStoreNoLlegados();
    switchSkin(skin);
  }, [skin, clientSelect]);

  useEffect(() => {
    filterData();
  }, [selectedEstablecimiento, selectedTag, startDate, endDate, dataGrid]);

  const GetDataGridStoreNoLlegados = async () => {
    console.log("Fetching data from Firebase");
    setisLoading(true);

    try {
      const querySnapshot = await getDocs(collection(db, "Auditorias_Llenas"));
      const data = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          let auditoria = doc.data();
          const estQuery = query(
            collection(db, "Establecimientos_Auditoria"),
            where("nombre_est", "==", auditoria.establecimiento)
          );
          const estSnapshot = await getDocs(estQuery);
          if (!estSnapshot.empty) {
            const estData = estSnapshot.docs[0].data();
            const estDataWithPrefix = Object.fromEntries(
              Object.entries(estData).map(([key, value]) => [
                `estable_${key}`,
                value,
              ])
            );
            auditoria = { ...auditoria, ...estDataWithPrefix };
          }
          return auditoria.preguntas.map((pregunta) => ({
            ...auditoria,
            pregunta: pregunta.pregunta,
            respuesta: pregunta.respuesta,
            tag: pregunta.tag,
            tipo: pregunta.tipo,
            preguntas: [], // Limpia la propiedad preguntas
          }));
        })
      );
      const flattenedData = data.flat();
      console.log("Data fetched from Firebase:", flattenedData);

      SetDataGrid(flattenedData);
      setFilteredData(flattenedData);
      setisLoading(false);
    } catch (error) {
      console.error("Error fetching data from Firebase:", error);
      setisLoading(false);
    }
  };

  const filterData = () => {
    let filtered = dataGrid;
    if (
      selectedEstablecimiento &&
      selectedEstablecimiento.value !== "Todos los establecimientos"
    ) {
      filtered = filtered.filter(
        (item) => item.establecimiento === selectedEstablecimiento.value
      );
    }
    if (selectedTag && selectedTag.value !== "Todos los tags") {
      filtered = filtered.filter((item) => item.tag === selectedTag.value);
    }
    filtered = filtered.filter((item) => {
      const itemDate = new Date(item.fecha_Completado);
      const itemDateOnly = new Date(itemDate.getFullYear(), itemDate.getMonth(), itemDate.getDate());
      const startDateOnly = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
      const endDateOnly = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
      return itemDateOnly >= startDateOnly && itemDateOnly <= endDateOnly;
    });
    setFilteredData(filtered);
    updateCardData(filtered);
  };

  const updateCardData = (filtered) => {
    const skuData = filtered.filter(item => item.tag === "sku");
    const skuYesCount = skuData.filter(item => item.respuesta === true).length;
    const skuNoCount = skuData.filter(item => item.respuesta === false).length;
    const totalSkuCount = skuYesCount + skuNoCount;
    setSkuYesPercentage(totalSkuCount ? (skuYesCount / totalSkuCount) * 100 : 0);
    setSkuNoPercentage(totalSkuCount ? (skuNoCount / totalSkuCount) * 100 : 0);
  
    const popData = filtered.filter(item => item.tag === "pop");
    const popYesCount = popData.filter(item => item.respuesta === true).length;
    const popNoCount = popData.filter(item => item.respuesta === false).length;
    const totalPopCount = popYesCount + popNoCount;
    setPopYesPercentage(totalPopCount ? (popYesCount / totalPopCount) * 100 : 0);
    setPopNoPercentage(totalPopCount ? (popNoCount / totalPopCount) * 100 : 0);
  
    const exhibicionData = filtered.filter(item => item.tag === "exhibicion");
    const exhibicionYesCount = exhibicionData.filter(item => item.respuesta === true).length;
    const exhibicionNoCount = exhibicionData.filter(item => item.respuesta === false).length;
    const totalExhibicionCount = exhibicionYesCount + exhibicionNoCount;
    setExhibicionYesPercentage(totalExhibicionCount ? (exhibicionYesCount / totalExhibicionCount) * 100 : 0);
    setExhibicionNoPercentage(totalExhibicionCount ? (exhibicionNoCount / totalExhibicionCount) * 100 : 0);
  };

  const establecimientosOptions = [
    {
      value: "Todos los establecimientos",
      label: "Todos los establecimientos",
    },
    ...new Set(dataGrid.map((item) => item.establecimiento)),
  ].map((est) => (typeof est === "string" ? { value: est, label: est } : est));

  console.log(establecimientosOptions);

  const tagsOptions = [
    { value: "Todos los tags", label: "Todos los tags" },
    { value: "sku", label: "Presencia" },
    { value: "pop", label: "Material POP" },
    { value: "exhibicion", label: "Exhibición" },
  ]


  //   ...new Set(dataGrid.map((item) => item.tag).filter((tag) => tag)),
  // ].map((tag) => ({
  //   value: tag,
  //   label:
  //     tag == "sku"
  //       ? "Presencia"
  //       : tag == "pop"
  //       ? "Material POP"
  //       : tag == "exhibicion"
  //       ? "Exhibición"
  //       : tag,
  // }));

  // const tagsOptions = [
  //   { value: "Todos los tags", label: "Todos los tags" },
  //   ...new Set(dataGrid.map((item) => item.tag).filter((tag) => tag)),
  // ].map((tag) => ({
  //   value: tag,
  //   label:
  //     tag == "sku"
  //       ? "Presencia"
  //       : tag == "pop"
  //       ? "Material POP"
  //       : tag == "exhibicion"
  //       ? "Exhibición"
  //       : tag,
  // }));

  function cellRenderRespuesta(data) {
    const valor = data.value;
    if (typeof valor === "boolean") {
      return valor ? "Sí" : "No";
    }
    return valor;
  }

  function customizeText(e) {
    if (e.value === true) {
      return "Sí";
    }
    if (e.value === false) {
      return "No";
    }
    return e.value;
  }

  const onExporting = (e) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Auditorias");

    exportDataGrid({
      component: dataGridRef.current.instance,
      worksheet: worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "Auditorias.xlsx"
        );
      });
    });
    e.cancel = true;
  };

  return (
    <>
      {/* {isLoading && <Loader />} */}
      <React.Fragment>
        <Header onSkin={setSkin} />
        <div className="grid_store_no_alcanzados main-app p-3 p-lg-4">
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <div>
              <h4 className="main-title mb-0">Auditorias</h4>
            </div>
            <div className="d-flex" style={{width:"90%"}}>
              <Col>
                <span>Establecimientos</span>
                <Select
                  options={establecimientosOptions}
                  onChange={setSelectedEstablecimiento}
                  placeholder="Filtrar por Establecimiento"
                  className="me-2"
                />
              </Col>

              <Col>
                <span>Tag</span>
                <Select
                  options={tagsOptions}
                  onChange={setSelectedTag}
                  placeholder="Filtrar por Tag"
                  className="me-2"
                />
              </Col>

              <Col style={{marginRight:"8px"}}>
                <span>Desde</span>
                <ReactDatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="form-control me-2"
                />
              </Col>
              <Col>
                <span>Hasta</span>
                <ReactDatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  className="form-control me-2"
                />
              </Col>

              {/* <ReactDatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              placeholderText="Desde"
              className="me-2"
            />
            <ReactDatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              placeholderText="Hasta"
              className="me-2"
            /> */}
            </div>
          </div>

          <div className="d-flex mb-4" style={{ width: "100%" }}>
            <Card
              className="me-2"
              style={{ flex: 1, height: "9rem", backgroundColor: "#ffffff" }}
            >
              <Card.Body>
                <Card.Title style={{ color: "#506fd9" }}>
                  <i
                    className="ri-bar-chart-box-line me-2"
                    style={{ color: "#506fd9" }}
                  ></i>
                  Presencia
                </Card.Title>
                <Card.Text>
                  <div className="d-flex justify-content-between">
                    <div style={{ color: "#0cb785", fontSize: "1.2rem" }}>
                      <strong>Sí:</strong> {skuYesPercentage.toFixed(2)}%
                    </div>
                    <div style={{ color: "#dc3545", fontSize: "1.2rem" }}>
                      <strong>No:</strong> {skuNoPercentage.toFixed(2)}%
                    </div>
                  </div>
                  <div className="mt-2">
                    <ProgressBar>
                      <ProgressBar
                        now={skuYesPercentage}
                        style={{ backgroundColor: "#0cb785" }}
                        key={1}
                      />
                      <ProgressBar
                        now={skuNoPercentage}
                        variant="danger"
                        key={2}
                      />
                    </ProgressBar>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card
              className="me-2"
              style={{ flex: 1, height: "9rem", backgroundColor: "#ffffff" }}
            >
              <Card.Body>
                <Card.Title style={{ color: "#506fd9" }}>
                  <i
                    className="ri-pie-chart-line me-2"
                    style={{ color: "#506fd9" }}
                  ></i>
                  Material POP
                </Card.Title>
                <Card.Text>
                  <div className="d-flex justify-content-between">
                    <div style={{ color: "#0cb785", fontSize: "1.2rem" }}>
                      <strong>Sí:</strong> {popYesPercentage.toFixed(2)}%
                    </div>
                    <div style={{ color: "#dc3545", fontSize: "1.2rem" }}>
                      <strong>No:</strong> {popNoPercentage.toFixed(2)}%
                    </div>
                  </div>
                  <div className="mt-2">
                    <ProgressBar>
                      <ProgressBar
                        now={popYesPercentage}
                        style={{ backgroundColor: "#0cb785" }}
                        key={1}
                      />
                      <ProgressBar
                        now={popNoPercentage}
                        variant="danger"
                        key={2}
                      />
                    </ProgressBar>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card
              className="me-2"
              style={{ flex: 1, height: "9rem", backgroundColor: "#ffffff" }}
            >
              <Card.Body>
                <Card.Title style={{ color: "#506fd9" }}>
                  <i
                    className="ri-store-line me-2"
                    style={{ color: "#506fd9" }}
                  ></i>
                  Exhibición
                </Card.Title>
                <Card.Text>
                  <div className="d-flex justify-content-between">
                    <div style={{ color: "#0cb785", fontSize: "1.2rem" }}>
                      <strong>Sí:</strong> {exhibicionYesPercentage.toFixed(2)}%
                    </div>
                    <div style={{ color: "#dc3545", fontSize: "1.2rem" }}>
                      <strong>No:</strong> {exhibicionNoPercentage.toFixed(2)}%
                    </div>
                  </div>
                  <div className="mt-2">
                    <ProgressBar>
                      <ProgressBar
                        now={exhibicionYesPercentage}
                        style={{ backgroundColor: "#0cb785" }}
                        key={1}
                      />
                      <ProgressBar
                        now={exhibicionNoPercentage}
                        style={{ backgroundColor: "#dc3545" }}
                        key={2}
                      />
                    </ProgressBar>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className="height-grid-75">
            <DataGrid
              ref={dataGridRef}
              dataSource={filteredData}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnResizingMode="widget"
              columnAutoWidth={true}
              showRowLines={false}
              showBorders={true}
              rowAlternationEnabled={true}
              displayMode="full"
              style={{ height: "75vh", width: "100%" }}
              wordWrapEnabled={true}
              onExporting={onExporting}
            >
              {/* <Selection mode="multiple" /> */}
              <Export enabled={true} />
              <GroupPanel visible={true} />
              <ColumnChooser enabled={true} />
              <Scrolling rowRenderingMode="infinity"></Scrolling>
              <ColumnFixing enabled={true} />
              <HeaderFilter visible={true} />

              <Column dataField="cliente" caption="Cliente" width={150}>
                <HeaderFilter allowSelectAll={true}>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField="regiones" caption="Regiones" width={150}>
                <HeaderFilter allowSelectAll={true}>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField="promotor" caption="Promotor" width={150}>
                <HeaderFilter allowSelectAll={true}>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column
                dataField="fecha_Completado"
                caption="Fecha Completado"
                width={150}
              >
                <HeaderFilter allowSelectAll={true}>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField="nombre" caption="Nombre" width={150}>
                <HeaderFilter allowSelectAll={true}>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              {/* <Column dataField= 'estatus' caption= 'Estatus' width={150}  >
              <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column> */}
              <Column
                dataField="establecimiento"
                caption="Establecimiento"
                width={150}
              >
                <HeaderFilter allowSelectAll={true}>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField="estable_rif" caption="RIF" width={150}>
                <HeaderFilter allowSelectAll={true}>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              {/* <Column dataField= 'estable_cod_est' caption= 'Código Establecimiento' width={150}  >
              <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column> */}
              {/* <Column dataField= 'estable_municipio' caption= 'Municipio' width={150}  >
              <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column> */}
              {/* <Column dataField= 'estable_telefono_contacto' caption= 'Teléfono Contacto' width={150}  >
              <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'estable_direccion' caption= 'Dirección' width={150}  >
              <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column> */}
              <Column dataField="pregunta" caption="Pregunta" width={150}>
                <HeaderFilter allowSelectAll={true}>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column
                dataField="respuesta"
                caption="Respuesta"
                width={150}
                cellRender={cellRenderRespuesta}
                customizeText={customizeText}
              >
                <HeaderFilter allowSelectAll={true}>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
            </DataGrid>
          </div>
          <Footer />
        </div>
      </React.Fragment>
    </>
  );
}
